import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

const Head = ({ title }) => {
const metaDataTitle = useStaticQuery(graphql`
  query myTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    return(
        <Helmet title={ `${title} | ${metaDataTitle.site.siteMetadata.title }`} />
    )
}

export default Head